import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

function getDefaultState() {
  if (typeof window === "undefined") {
    return {
      hasAskedForCookies: false,
      cookiesEnabled: false
    };
  }

  const hasAskedForCookies = !!localStorage.getItem("cookie-banner-disabled");
  const cookiesEnabled = document.cookie.includes("gatsby-gdpr-google-analytics=true");

  return {
    hasAskedForCookies,
    cookiesEnabled
  };
}

const cookieContext = React.createContext(getDefaultState());

const { Provider } = cookieContext;

export const CookieStateProvider = ({ children }) => {
  const [cookieState, setCookieState] = useState(getDefaultState());

  useEffect(() => {
    if (cookieState.cookiesEnabled) {
    }
  }, [cookieState.cookiesEnabled]);

  const setCookiesEnabled = () => {
    initializeAndTrack(Location);

    localStorage.setItem("cookie-banner-disabled", "true");
    document.cookie = "gatsby-gdpr-google-analytics=true";

    setCookieState({
      hasAskedForCookies: true,
      cookiesEnabled: true
    });
  };

  const setCookiesDisabled = () => {
    localStorage.setItem("cookie-banner-disabled", "true");

    setCookieState({
      hasAskedForCookies: true,
      cookiesEnabled: false
    });
  };

  // @ts-ignore
  return <Provider value={{ ...cookieState, setCookiesEnabled, setCookiesDisabled }}>{children}</Provider>;
};

export function useCookieState() {
  return useContext(cookieContext);
}
