import * as React from "react";
import { useState } from "react";
import { useCookieState } from "./cookie-state";
import "./cookie.scss";
import { Link } from "gatsby";

const CookieBanner = () => {
  // @ts-ignore
  const { hasAskedForCookies, setCookiesEnabled, setCookiesDisabled } = useCookieState();

  const [hidden, setHidden] = useState(hasAskedForCookies);
  const [transitionDone, setTransitionDone] = useState(hidden);

  if (typeof window === "undefined" || (hidden && transitionDone)) {
    return null;
  }

  const disableTracking = () => {
    setCookiesDisabled();
    setHidden(true);
    setTransitionDone(false);
  };

  const enableTracking = () => {
    setCookiesEnabled();
    setHidden(true);
    setTransitionDone(false);
  };

  const onTransitionEnd = () => {
    setTransitionDone(true);
  };

  return (
    <div
      className={`cookie-banner-wrapper flex justify-end fixed top-0 left-0 bottom-0 right-0 ${hidden ? "fade-out" : ""}`}
      onTransitionEnd={onTransitionEnd}
    >
      <div className={`cookie-banner-inner bg-white text-black text-center py-8 ${hidden ? "fade-out" : ""}`}>
        <div className={`w-full max-w-full px-4`}>
          <div className={`cookie-banner-content flex`}>
            <div className={`text-center`}>
              <div className={`text-base font-normal leading-7`}>
                Cookies helfen uns bei der Bereitstellung unserer Inhalte und Dienste.
                Durch die weitere Nutzung der Webseite stimmst du der Verwendung von Cookies zu.
              </div>
              <div className={`inline`}>
                <Link className={"text-yellow-600"} to="/privacy">
                  Mehr Informationen kannst du in unserer Datenschutzerklärung finden.
                </Link>
              </div>
            </div>
            <div className={"flex flex-no-wrap justify-center my-8 flex-col md:flex-row"}>
              <button className={"mx-6 py-4 px-8 bg-gray-600 text-white"}
                      onClick={disableTracking}
              >
                Cookies ablehnen
              </button>
              <button className={"mx-6 py-4 px-8 bg-yellow-500"}
                      onClick={enableTracking}
              >
                Cookies erlauben
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CookieBanner.propTypes = {};
CookieBanner.defaultProps = {};

export default CookieBanner;
