import "typeface-heebo"
import "./fonts.scss"
import "./home.scss"
import * as React from "react"
import NavbarComponent from "../navbar/navbar.component"
import FooterComponent from "../footer/footer.component"
import CookieBanner from "../cookie/cookie"
import { CookieStateProvider } from "../cookie/cookie-state"

export default function Home({ children }) {
  return (
    <CookieStateProvider>
      <div className={"mam-page antialiased bg-gray-100"}>
        <NavbarComponent navigationLinks={[
          { slug: "/", name: "Home" },
          { slug: "https://shop.mira-and-me.de/", name: "Shop" },
          { slug: "/co2", name: "CO² Ausgleich" }
        ]}
                         logo={"/images/logo_black.png"} />
        <div className={"mam-container bg-gray-100"}>{children}</div>
        <FooterComponent copyright={"Mira & Me GmbH, All rights reserved."}
                         links={[{
                           title: "Impressum",
                           slug: "/impressum"
                         }, {
                           title: "Datenschutz",
                           slug: "/privacy"
                         }, {
                           title: "AGBs",
                           slug: "/agb"
                         }, {
                           title: "Cookie-Richtlinie",
                           slug: "/cookie"
                         }, {
                           title: "Shop",
                           slug: "https://shop.mira-and-me.de/"
                         }]} />
      </div>
      <CookieBanner />
    </CookieStateProvider>
  )
}
