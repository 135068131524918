// @ts-ignore
import React from "react";
import "./navbar.component.scss";
import { NavigationProps } from "../../types/navigation.props";

export default function NavbarComponent(props: NavigationProps) {
  return (
    <nav className="flex items-center justify-between flex-wrap bg-gray-100 py-6 navbar">
      <div className="flex items-center flex-shrink-0 text-black mr-6 cursor-pointer">
        <a href="/">
          <img className="h-12 mr-4" src={props.logo} alt="Mira + me" />
        </a>
      </div>
      <div>{
        props.navigationLinks.map((item, index) => {
            return <a key={index} href={item.slug} className="link hover:text-gray-500">{item.name}</a>;
          }
        )}
      </div>
    </nav>
  );
}
